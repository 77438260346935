import React from 'react';
import GoogleButton from 'react-google-button';
import { Grid } from '@mui/material';
import { signInWithGoogle } from '../apis/firebase';

export default function Login() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <GoogleButton
          onClick={signInWithGoogle}
        />
      </Grid>
    </Grid>

  );
}

import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Grid, MenuItem, Select, TextField, FormControl, InputLabel, FormHelperText, FormControlLabel, Switch } from '@mui/material';
import AppContext from '../components/AppContext';
import { saveUser } from '../apis/user_api';

export default function User(props) {
  const [isSaving, setIsSaving] = React.useState(false);
  const [adminValue, setAdminValue] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [nameError, setNameError] = useState(null);
  const [phoneValue, setPhoneValue] = useState('');
  const [phoneError, setPhoneError] = useState(null);
  const [roleValue, setRoleValue] = useState('');
  const [roleError, setRoleError] = useState(null);
  const [fleetValue, setFleetValue] = useState('');
  const [fleetError, setFleetError] = useState(null);
  
  const appContext = useContext(AppContext);
  
  useEffect(() => {
    setAdminValue(Boolean(props?.user?.admin));
    setNameValue(props?.user?.name || '');
    setPhoneValue(props?.user?.phone || '');
    setFleetValue(props?.user?.fleet || '');
    setRoleValue(props?.user?.role || '');


  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleNameChange = (value) => {
    setNameError(null);
    setNameValue(value);
  }

  const _handlePhoneChange = (value) => {
    setPhoneError(null);
    setPhoneValue(value);
  }

  const _handleRoleChange = (value) => {
    setRoleError(null);
    setRoleValue(value);
  }

  const _handleFleetChange = (value) => {
    setFleetError(null);
    setRoleError(null);
    setFleetValue(value);
  }

  const _save = async () => {
    let valid = true;
    if (!nameValue) {
      setNameError('Name is required');
      valid = false;
    }
    if (fleetValue) {
      if (!roleValue) {
        setRoleError('Role is required');
        valid = false;
      }
    }
    if (!valid) {
      return;
    }

    const data = {id: props?.user?.id, admin: adminValue, name: nameValue, phone: phoneValue || null, role: roleValue || null, fleet: fleetValue || null};

    try {
      setIsSaving(true);
      await saveUser(data);
      props.changedData(data);
      appContext.setSuccessMessage('User saved successfully');
    } catch (err) {
      appContext.setErrorMessage(String(err));
    } finally {
      setIsSaving(false);
    }
  }

  return <>
    <Grid container direction="column" alignItems="center" >
      {Boolean(props?.user) && (
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={12} md={1}>
            <Avatar style={{marginRight: 10}} alt={props?.user.name} src={props?.user.avatar} referrerPolicy="no-referrer" imgProps={{referrerPolicy: "no-referrer"}} />
          </Grid>
          <Grid container item xs={12} sm={12} md={11} justifyContent="center">
            <div style={{ width: '100%', paddingTop: 5 }} >
              <Box
                component="form"
                noValidate
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={(e) => _handleNameChange(e.target.value)}
                      disabled={isSaving}
                      error={Boolean(nameError)}
                      id="user-name"
                      label="Name"
                      value={nameValue}
                      helperText={nameError}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={(e) => _handlePhoneChange(e.target.value)}
                      disabled={isSaving}
                      error={Boolean(phoneError)}
                      id="phone"
                      label="Phone"
                      value={phoneValue}
                      helperText={phoneError}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="fleet-label" htmlFor="fleet-select">Fleet</InputLabel>
                      <Select id="fleet-select"
                        aria-describedby="fleetr-helper-text"
                        labelId="fleet-label"
                        value={fleetValue}
                        error={Boolean(fleetError)}
                        label="Fleet"
                        onChange={(e) => _handleFleetChange(e.target.value)}
                      >
                        <MenuItem key="fleet-menu-item" value=""><em>None</em></MenuItem>
                        {appContext.allFleets.map((fleet) => <MenuItem key={`fleet-menu-item-${fleet.id}`} value={fleet.id}>{fleet.name}</MenuItem>)}
                      </Select>
                      <FormHelperText error={Boolean(fleetError)} id="fleetr-helper-text">{fleetError}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="role-label" htmlFor="role-select">Fleet role</InputLabel>
                      <Select id="role-select"
                        aria-describedby="roler-helper-text"
                        labelId="role-label"
                        value={roleValue}
                        error={Boolean(roleError)}
                        label="Fleet role"
                        onChange={(e) => _handleRoleChange(e.target.value)}
                      >
                        <MenuItem key="role-menu-item" value=""><em>None</em></MenuItem>
                        <MenuItem key="role-menu-item-driver" value="driver">Driver</MenuItem>
                        <MenuItem key="role-menu-item-manager" value="manager">Manager</MenuItem>
                        
                      </Select>
                      <FormHelperText error={Boolean(roleError)} id="fleetr-helper-text">{roleError}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControlLabel control={<Switch checked={adminValue} onChange={(e) => setAdminValue(e.target.checked)} />} label="Admin" />
                  </Grid>
                </Grid>
              </Box>
              <Grid item container direction="row" justifyContent="space-evenly" position="relative" marginTop={4}>
                <Button variant="outlined" disabled={isSaving} onClick={() => props.changedData(null)}>Back</Button>
                <Button variant="contained" disabled={isSaving} onClick={_save}>Save</Button>
                  {isSaving && (
                  <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </Grid>
  </>;
}

import React, { useContext, useEffect, useState } from 'react';
import { AppBar, Avatar, Box, CircularProgress, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeviceIcon from '@mui/icons-material/Videocam';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import FleetIcon from '@mui/icons-material/Commute';
import UsersIcon from '@mui/icons-material/People';
import VehicleIcon from '@mui/icons-material/FireTruck';
import AppContext from './AppContext';
import { auth, handleSignInRedirect } from '../apis/firebase';
import './app.css'

export default function AppContainer({children}) {
  const appContext = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedSignIn, setCheckedSignIn] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const currentUser = appContext.currentUser;

  useEffect(() => {
    const init = async () => {
      if (!currentUser) {
        await handleSignInRedirect();
        if (auth.currentUser) {
          const idTokenResult = await auth.currentUser.getIdTokenResult(true);
          const isAdmin = Boolean(idTokenResult.claims?.admin);
          if (isAdmin) {
            appContext.setIsAdmin(true);
            appContext.setRole(Boolean(idTokenResult.claims?.role));
            appContext.setCurrentUser(auth.currentUser);
  
          } else {
            appContext.setIsAdmin(false);
            appContext.setRole(undefined);
            appContext.setCurrentUser(null);
            appContext.setErrorMessage("User is not an admin!");
            auth.signOut();
          }
        }
      }
      if (currentUser) {
      }
      setCheckedSignIn(true);
    };

    init();
  }, [appContext, currentUser]);

  const drawerWidth = 140;

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleUserMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    setAnchorEl(null);
    await auth.signOut();
    appContext.setCurrentUser(auth.currentUser);
  };

  const drawer = (
    <div>
      <Toolbar disableGutters>
        <List>
          <ListItem key="menu-fleet" disablePadding>
            <NavLink
              to="/"
              className="nav-link"
              onClick={() => setOpenDrawer(false)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      </Toolbar>
      <Divider />
      {appContext.currentUser && appContext.isAdmin && (
        <List>
          <ListItem key="menu-fleet" disablePadding>
            <NavLink
              to="/fleets"
              className="nav-link"
              onClick={() => setOpenDrawer(false)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <FleetIcon />
                </ListItemIcon>
                <ListItemText primary="Fleets" />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem key="menu-vehicle" disablePadding>
            <NavLink
              to="/vehicles"
              className="nav-link"
              onClick={() => setOpenDrawer(false)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <VehicleIcon />
                </ListItemIcon>
                <ListItemText primary="Vehicles" />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem key="menu-device" disablePadding>
            <NavLink
              to="/devices"
              className="nav-link"
              onClick={() => setOpenDrawer(false)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DeviceIcon />
                </ListItemIcon>
                <ListItemText primary="Devices" />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem key="menu-user" disablePadding>
            <NavLink
              to="/users"
              className="nav-link"
              onClick={() => setOpenDrawer(false)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <UsersIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      )}
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Sentinel Admin
          </Typography>
          {appContext.currentUser?.photoURL && (
            <IconButton onClick={handleUserMenuOpen} sx={{ p: 0 }}>
              <Avatar alt={appContext.currentUser.displayName} src={appContext.currentUser.photoURL} referrerPolicy="no-referrer" imgProps={{referrerPolicy: "no-referrer"}} />
              
            </IconButton>
          )}
          {appContext.currentUser && !appContext.currentUser?.photoURL && (
            <IconButton
              size="large"
              onClick={handleUserMenuOpen}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
          )}
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleUserMenuClose}
          >
            <MenuItem onClick={handleSignOut}>
              <Typography textAlign="center">Sign out</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={openDrawer}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { xs: '100%', sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {appContext.currentUser && <Toolbar />}
        {!checkedSignIn && (<div style={{textAlign: 'center', marginTop: 100}}> <CircularProgress /></div>)}
        {checkedSignIn && (children)}
      </Box>
    </Box>
  );
}

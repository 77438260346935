import React, { useContext, useEffect, useState } from 'react';
import { Alert, Avatar, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import AppContext from '../components/AppContext';
import { getFleets } from '../apis/fleet_api';
import { getUsers } from '../apis/user_api';
import User from './User';

export default function Users() {
  const [notFound, setNotFound] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [isLoadingFleets, setIsLoadingFleets] = React.useState(false);
  const [filterNameValue, setFilterNameValue] = useState('');
  const [filterEmailValue, setFilterEmailValue] = useState('');
  const [filterFleetValue, setFilterFleetValue] = React.useState('');
  const [users, setUsers] = React.useState([]);
  const [editingIndex, setEditingIndex] = React.useState(-1);
  const [filteredName, setFilteredName] = React.useState('');
  const [filteredEmail, setFilteredEmail] = React.useState('');
  const [filteredFleet, setFilteredFleet] = React.useState('');
  const [showLoadMore, setShowLoadMore] = React.useState(false);
  const appContext = useContext(AppContext);

  const _search = async () => {
    try {
      setIsLoading(true);
      setFilteredName(filterNameValue);
      setFilteredEmail(filterEmailValue);
      setFilteredFleet(filterFleetValue);
      const response = await getUsers(filterNameValue || null, filterEmailValue || null, filterFleetValue || null);
      setUsers(response.users);
      setNotFound(Boolean(response.users.length === 0));
      setShowLoadMore(response.pageSize === response.users.length);
    } catch (err) {
      console.error(err);
      appContext.setErrorMessage(String(err));
    } finally {
      setIsLoading(false);
    }
  }

  const _loadMore = async () => {
    try {
      setIsLoadingMore(true);
      const response = await getUsers(filteredName || null, filteredEmail || null, filteredFleet || null, users[users.length - 1]?.id);

      setUsers(users.concat(response.users));
      setShowLoadMore(response.pageSize === response.users.length);
    } catch (err) {
      console.error(err);
      appContext.setErrorMessage(String(err));
    } finally {
      setIsLoadingMore(false);
    }
  };

  const _changedData = (data) => {
    if (data) {
      users[editingIndex] = { ...users[editingIndex], ...data }
      setUsers(users);
    }

    setEditingIndex(-1);
  }

  useEffect(() => {
    const init = async () => {
      if (appContext.allFleets.length === 0) {
        try {
          setIsLoadingFleets(true);
          const response = await getFleets();
          appContext.setAllFleets(response);
        } catch (err) {
          console.error(err);
          appContext.setErrorMessage(String(err));
        } finally {
          setIsLoadingFleets(false);
        }
      }
    };
  
    init();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>

    <>
      <Box
        component="form"
        noValidate
        onSubmit={(e) => {e.preventDefault(); _search();}}
      >
        <Grid container justifyContent="space-between" alignItems="left" flexDirection={{ xs: 'column', sm: 'row' }} spacing={2} marginBottom={2}>
          <Grid>
            <Typography variant="h4" gutterBottom>Users</Typography>
          </Grid>
          <Grid container columnSpacing={1} flexDirection={{ xs: 'column', sm: 'row' }} >
            <Grid>
              <TextField
                onChange={(e) => setFilterNameValue(e.target.value)}
                disabled={isLoading}
                id="filter-user-name"
                label="Name starts with"
                value={filterNameValue}
              />
            </Grid>
            <Grid>
              <TextField
                onChange={(e) => setFilterEmailValue(e.target.value)}
                disabled={isLoading}
                id="filter-email"
                label="E-mail"
                value={filterEmailValue}
              />
            </Grid>
            <Grid>
              <FormControl>
                <InputLabel id="filter-fleet-label" htmlFor="filter-fleet-select">Fleet</InputLabel>
                <Select id="filter-fleet-select"
                  style={{minWidth: 80}}
                  aria-describedby="fleetr-helper-text"
                  labelId="filter-fleet-label"
                  value={filterFleetValue}
                  label="Fleet"
                  onChange={(e) => setFilterFleetValue(e.target.value)}
                  disabled={isLoading}
                  displayEmpty
                  sx={{justifyContent: 'center'}}
                >
                  <MenuItem key="filter-fleet-menu-item-all" value="" sx={{height: 36, justifyContent: 'center'}}> {isLoadingFleets && <CircularProgress size={24} />}</MenuItem>
                  <MenuItem key="filter-fleet-menu-item-no" value="__no__"><em>No fleet assigned</em></MenuItem>
                  {appContext.allFleets.map((fleet) => <MenuItem key={`filter-fleet-menu-item-${fleet.id}`} value={fleet.id}>{fleet.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid paddingTop={{xs: 0, sm: 2}}>
              <Button type='submit'>Search</Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {isLoading && (<div style={{textAlign: 'center', marginTop: 100}}> <CircularProgress /></div>)}

      {!isLoading && notFound && (
        <Alert>
          No users found
        </Alert>
      )}

      {!isLoading && Boolean(users.length) && (
        <Paper sx={{ width: '100%' }}>
          <>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell key='header-avatar' align='right'>
                    </TableCell>
                    <TableCell
                      key={'header-name'}
                      style={{ fontSize: 'large' }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      key={'header-email'}
                      style={{ fontSize: 'large' }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      key={'header-phone'}
                      style={{ fontSize: 'large' }}
                    >
                      Phone
                    </TableCell>
                    <TableCell
                      key={'header-fleet'}
                      style={{ fontSize: 'large' }}
                    >
                      Fleet
                    </TableCell>
                    <TableCell
                      key={'header-role'}
                      style={{ fontSize: 'large' }}
                    >
                      Fleet role
                    </TableCell>
                    <TableCell
                      key={'header-admin'}
                      style={{ fontSize: 'large' }}
                    >
                      Sentinel admin
                    </TableCell>
                    <TableCell key='header-edit' align='right'>
                      <div />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={`user-${user.id}`}>
                        <TableCell key={`user-avatar-${user.id}`} align='right'>
                          {user.avatar && (
                            <Avatar alt={user.name} src={user.avatar} referrerPolicy="no-referrer" imgProps={{referrerPolicy: "no-referrer"}} />
                          )}
                          {!user.avatar && (
                            <Avatar>
                              <AccountCircleIcon />
                            </Avatar>
                          )}
                        </TableCell>
                        <TableCell key={`user-${user.id}-column-name`}>
                          {user.name}
                        </TableCell>
                        <TableCell key={`user-${user.id}-column-email`}>
                          {user.email}
                        </TableCell>
                        <TableCell key={`user-${user.id}-column-phone`}>
                          {user.phone}
                        </TableCell>
                        <TableCell key={`user-${user.id}-column-fleet`}>
                          {user.fleet}
                        </TableCell>
                        <TableCell key={`user-${user.id}-column-role`}>
                          {user.role}
                        </TableCell>
                        <TableCell key={`user-${user.id}-column-admin`}>
                          {user.admin ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell key={`user-edit-${user.id}`} align='right'>
                          <IconButton onClick={() => setEditingIndex(index)} disabled={editingIndex > -1}><EditIcon /></IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {showLoadMore && (
              <Grid container direction="row" justifyContent="space-evenly" position="relative" marginTop={4}>
                <Button onClick={_loadMore}>Load more</Button>
                {isLoadingMore && 
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      marginTop: '-12px',
                      marginLeft: '140px',
                    }}
                  />
                }
              </Grid>
            )}
          </>
        </Paper>
      )}

    </>

    {editingIndex > -1 && (
      <Dialog open={editingIndex > -1} >
        <DialogTitle>Edit user</DialogTitle>
        <DialogContent>
          <User user={users[editingIndex]} changedData={_changedData} />
        </DialogContent>
      </Dialog>
    )}

  </>;
}

import React, { useContext } from 'react';
import AppContext from '../components/AppContext';
import Login from './Login';

export default function Home() {
  const appContext = useContext(AppContext);


  if (!appContext.currentUser) {
    return (
      <Login />
    );  
  }

  return null;

}

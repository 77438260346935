import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../components/AppContext';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import DeviceIcon from '@mui/icons-material/Videocam';
import EditIcon from '@mui/icons-material/Edit';
import { getFleets } from '../apis/fleet_api';
import { deleteVehicle, getVehicles } from '../apis/vehicle_api';
import Vehicle from './Vehicle';
import { NavLink } from 'react-router-dom';

export default function Vehicles() {
  const [notFound, setNotFound] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [isLoadingFleets, setIsLoadingFleets] = React.useState(false);
  const [filterNameValue, setFilterNameValue] = useState('');
  const [filterIdValue, setFilterIdValue] = useState('');
  const [filterFleetValue, setFilterFleetValue] = React.useState('');
  const [vehicles, setVehices] = React.useState([]);
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletingIndex, setDeletingIndex] = React.useState(-1);
  const [editingIndex, setEditingIndex] = React.useState(-1);
  const [filteredName, setFilteredName] = React.useState('');
  const [filteredId, setFilteredId] = React.useState('');
  const [filteredFleet, setFilteredFleet] = React.useState('');
  const [showLoadMore, setShowLoadMore] = React.useState(false);
  const appContext = useContext(AppContext);

  const _search = async () => {
    try {
      setIsLoading(true);
      setFilteredName(filterNameValue);
      setFilteredId(filterIdValue);
      setFilteredFleet(filterFleetValue);
      const response = await getVehicles(filterNameValue || null, filterIdValue || null, filterFleetValue || null);
      setVehices(response.vehicles);
      setNotFound(Boolean(response.vehicles.length === 0));
      setShowLoadMore(response.pageSize === response.vehicles.length);
    } catch (err) {
      console.error(err);
      appContext.setErrorMessage(String(err));
    } finally {
      setIsLoading(false);
    }
  }

  const _delete = async () => {
    try {
      setIsDeleting(true);
      const index = deletingIndex;
      await deleteVehicle(vehicles[index].id, vehicles[index].fleetId);      
      vehicles.splice(index, 1);
      appContext.setSuccessMessage('Vehicle removed successfully');
    } catch (err) {
      console.error(err);
      appContext.setErrorMessage(String(err));
    } finally {
      setDeletingIndex(-1)
      setIsDeleting(false);
    }
  }

  const _loadMore = async () => {
    try {
      setIsLoadingMore(true);
      const response = await getVehicles(filteredName || null, filteredId || null, filteredFleet || null, vehicles[vehicles.length - 1]?.id, vehicles[vehicles.length - 1]?.fleetId);

      setVehices(vehicles.concat(response.vehicles));
      setShowLoadMore(response.pageSize === response.vehicles.length);
    } catch (err) {
      console.error(err);
      appContext.setErrorMessage(String(err));
    } finally {
      setIsLoadingMore(false);
    }
  };

  const _changedData = (data) => {
    if (data) {
      if (isAdding) {
        vehicles.push(data);
        setVehices(vehicles);  

      } else {
        vehicles[editingIndex] = { ...vehicles[editingIndex], ...data }
        setVehices(vehicles);  
      }
    }

    setEditingIndex(-1);
    setIsAdding(false);
  }

  useEffect(() => {
    const init = async () => {
      if (appContext.allFleets.length === 0) {
        try {
          setIsLoadingFleets(true);
          const response = await getFleets();
          appContext.setAllFleets(response);
        } catch (err) {
          console.error(err);
          appContext.setErrorMessage(String(err));
        } finally {
          setIsLoadingFleets(false);
        }
      }
    };
  
    init();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>

    <>
      <Box
        component="form"
        noValidate
        onSubmit={(e) => {e.preventDefault(); _search();}}
      >
        <Grid container justifyContent="space-between" alignItems="left" flexDirection={{ xs: 'column', sm: 'row' }} spacing={2} marginBottom={2}>
          <Grid>
            <Typography variant="h4" gutterBottom>Vehicles</Typography>
          </Grid>
          <Grid container columnSpacing={1} flexDirection={{ xs: 'column', sm: 'row' }} >
            <Grid>
              <TextField
                onChange={(e) => setFilterNameValue(e.target.value)}
                disabled={isLoading}
                id="filter-name"
                label="Name starts with"
                value={filterNameValue}
              />
            </Grid>
            <Grid>
              <TextField
                onChange={(e) => setFilterIdValue(e.target.value)}
                disabled={isLoading}
                id="filter-id"
                label="ID"
                value={filterIdValue}
              />
            </Grid>
            <Grid>
              <FormControl>
                <InputLabel id="filter-fleet-label" htmlFor="filter-fleet-select">Fleet</InputLabel>
                <Select id="filter-fleet-select"
                  style={{minWidth: 80}}
                  aria-describedby="fleetr-helper-text"
                  labelId="filter-fleet-label"
                  value={filterFleetValue}
                  label="Fleet"
                  onChange={(e) => setFilterFleetValue(e.target.value)}
                  disabled={isLoading}
                  displayEmpty
                >
                  <MenuItem key="filter-fleet-menu-item-all" value="" sx={{height: 36, justifyContent: 'center'}}> {isLoadingFleets && <CircularProgress size={24} />}</MenuItem>
                  {appContext.allFleets.map((fleet) => <MenuItem key={`filter-fleet-menu-item-${fleet.id}`} value={fleet.id}>{fleet.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid paddingTop={{xs: 0, sm: 2}}>
              <Button type='submit' disabled={isDeleting}>Search</Button>
            </Grid>
            <Grid paddingTop={{xs: 0, sm: 2}} paddingLeft={0}>
              <Button onClick={() => setIsAdding(true)} style={{paddingLeft: 2}} disabled={isDeleting}>New</Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {isLoading && (<div style={{textAlign: 'center', marginTop: 100}}> <CircularProgress /></div>)}

      {!isLoading && notFound && (
          <Alert>
            No vehicles found
          </Alert>
        )}

      {!isLoading && Boolean(vehicles.length) && (
        <Paper sx={{ width: '100%' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    key={'header-name'}
                    style={{ fontSize: 'large' }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    key={'header-fleet'}
                    style={{ fontSize: 'large' }}
                  >
                    Fleet
                  </TableCell>
                  <TableCell
                    key={'header-id'}
                    style={{ fontSize: 'large' }}
                  >
                    ID
                  </TableCell>
                  <TableCell key='header-edit' align='right' width={100}>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vehicles.map((vehicle, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={`vehicle-${vehicle.id}`}>
                      <TableCell key={`vehicle-${vehicle.id}-column-name`}>
                        {vehicle.name}
                      </TableCell>
                      <TableCell key={`vehicle-${vehicle.id}-column-fleet`}>
                        {vehicle.fleetId}
                      </TableCell>
                      <TableCell key={`vehicle-${vehicle.id}-column-id`}>
                        {vehicle.id}
                      </TableCell>
                      <TableCell key={`vehicle-edit-${vehicle.id}`} align='right' width={100}>
                        <div style={{display: 'flex'}}>
                          <IconButton onClick={() => setEditingIndex(index)} disabled={deletingIndex > -1 || editingIndex > -1}><EditIcon /></IconButton>
                          <NavLink to={`/devices?fleetId=${vehicle.fleetId}&vehicleId=${vehicle.id}`}>
                            <IconButton><DeviceIcon /></IconButton>
                          </NavLink>
                          {!(isDeleting &&  deletingIndex === index) && (
                            <IconButton onClick={() => setDeletingIndex(index)} disabled={deletingIndex > -1 || editingIndex > -1}><DeleteIcon /></IconButton>
                          )}
                          {isDeleting &&  deletingIndex === index && (
                            <div style={{paddingLeft: 8, paddingTop: 10}}>
                              <CircularProgress
                                size={24}
                              />
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {showLoadMore && (
              <Grid container direction="row" justifyContent="space-evenly" position="relative" marginTop={4}>
                <Button onClick={_loadMore}>Load more</Button>
                {isLoadingMore && 
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      marginTop: '-12px',
                      marginLeft: '140px',
                    }}
                  />
                }
              </Grid>
            )}
        </Paper>
      )}

    </>

    <Dialog open={isAdding} >
      <DialogTitle>New vehicle</DialogTitle>
      <DialogContent>
        <Vehicle fleetId={filterFleetValue} changedData={_changedData} />
      </DialogContent>
    </Dialog>
    <Dialog open={editingIndex > -1} >
      <DialogTitle>Edit vehicle</DialogTitle>
      <DialogContent>
        <Vehicle vehicle={vehicles[editingIndex]} changedData={_changedData} />
      </DialogContent>
    </Dialog>
    <Dialog
      open={Boolean(deletingIndex > -1 && !isDeleting)}
      onClose={() => setDeletingIndex(-1)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirm?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove this vehicle and all its device info?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeletingIndex(-1)}>No</Button>
        <Button onClick={_delete}>Yes</Button>
      </DialogActions>
    </Dialog>

  </>;
}

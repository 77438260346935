import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material';
import AppContext from '../components/AppContext';
import { saveFleet } from '../apis/fleet_api';

export default function Fleet(props) {
  const [isNew, setIsNew] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [idValue, setIdValue] = useState('');
  const [idError, setIdError] = useState(null);
  const [nameValue, setNameValue] = useState('');
  const [nameError, setNameError] = useState(null);
  
  const appContext = useContext(AppContext);

  useEffect(() => {
    setIsNew(!Boolean(props?.vehicle));
    setNameValue(props?.fleet?.name || '');
    setIdValue(props?.fleet?.id || props.fleetId || '');
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleIdChange = (value) => {
    setIdError(null);
    setIdValue(String(value).toLocaleUpperCase().replace(/ /g, '').replace(/\//g, '').replace(/\./g, ''));
  }

  const _handleNameChange = (value) => {
    setNameError(null);
    setNameValue(value);
  }

  const _save = async () => {
    let valid = true;
    if (!idValue) {
      setIdError('ID is required');
      valid = false;
    }
    if (idValue.match(/__.*__/)) {
      setIdError('ID is invalid');
      valid = false;
    }
    if (!nameValue) {
      setNameError('Name is required');
      valid = false;
    }
    if (!valid) {
      return;
    }

    const data = {id: idValue, name: nameValue};

    try {
      setIsSaving(true);
      await saveFleet(data);
      props.changedData(data);
      appContext.setSuccessMessage('Fleet saved successfully');
    } catch (err) {
      appContext.setErrorMessage(String(err));
    } finally {
      setIsSaving(false);
    }
  }

  return <>
    <Grid container direction="column" alignItems="center" >
      <div style={{ padding: 40, maxWidth: 600, width: '100%' }} >
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <TextField
                style={{ width: '100%' }}
                onChange={(e) => _handleIdChange(e.target.value)}
                disabled={isSaving || !isNew}
                error={Boolean(idError)}
                id="fleet-id"
                label="ID"
                value={idValue}
                helperText={idError}
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                style={{ width: '100%' }}
                onChange={(e) => _handleNameChange(e.target.value)}
                disabled={isSaving}
                error={Boolean(nameError)}
                id="fleet-name"
                label="Name"
                value={nameValue}
                helperText={nameError}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="space-evenly" position="relative" marginTop={4}>
            <Button variant="outlined" disabled={isSaving} onClick={() => props.changedData(null)}>Back</Button>
            <Button variant="contained" disabled={isSaving} onClick={_save}>Save</Button>
              {isSaving && (
              <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
          </Grid>
        </Box>
      </div>
    </Grid>
  </>;
}

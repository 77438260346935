import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Alert, Snackbar } from '@mui/material';
import AppContext from './components/AppContext';
import AppContainer from './components/AppContainer';
import Home from './pages/Home';
import Devices from './pages/Devices';
import Fleets from './pages/Fleets';
import Users from './pages/Users';
import Vehicles from './pages/Vehicles';


export default function App() {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [isAdmin, setIsAdmin] = useState(false);
  const [role, setRole] = useState(undefined);
  const [allFleets, setAllFleets] = useState([]);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);

  const appContext = {
    setErrorMessage,
    setSuccessMessage,
    currentUser,
    setCurrentUser,
    isAdmin,
    setIsAdmin,
    role,
    setRole,
    allFleets,
    setAllFleets,
  };

  return (
    <AppContext.Provider value={appContext}>
      <BrowserRouter>
        <AppContainer>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/fleets" element={appContext.currentUser && appContext.isAdmin ? <Fleets /> : <Home />} />
            <Route path="/vehicles" element={appContext.currentUser && appContext.isAdmin ? <Vehicles /> : <Home />} />
            <Route path="/devices" element={appContext.currentUser && appContext.isAdmin ? <Devices /> : <Home />} />
            <Route path="/users" element={appContext.currentUser && appContext.isAdmin ? <Users /> : <Home />} />
          </Routes>
        </AppContainer>
      </BrowserRouter>        
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setErrorMessage(undefined)}
        style={{marginTop: -15}}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setSuccessMessage(undefined)}
        style={{marginTop: -15}}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>
    </AppContext.Provider>
  );
};

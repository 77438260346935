import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Grid, MenuItem, Select, FormControl, InputLabel, FormHelperText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Autocomplete, TextField } from '@mui/material';
import { debounce } from '@mui/material/utils';
import AppContext from '../components/AppContext';
import { saveDevice, DEVICE_MODELS } from '../apis/device_api';
import { getVehicles } from '../apis/vehicle_api';
import { validate as uuidValidate } from 'uuid';



export default function Device(props) {
  const [isConfirming, setIsConfirming] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [idValue, setIdValue] = useState('');
  const [idError, setIdError] = useState(null);
  const [modelValue, setModelValue] = useState('');
  const [modelError, setModelError] = useState(null);
  const [fleetValue, setFleetValue] = useState('');
  const [fleetError, setFleetError] = useState(null);
  const [isFetchingVehicles, setIsFetchingVehicles] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleInputText, setVehicleInputText] = useState('');
  const [vehicleValue, setVehicleValue] = useState(null);
  const [vehicleError, setVehicleError] = useState(null);
  
  const appContext = useContext(AppContext);
  
  useEffect(() => {
    setIdValue(props?.device?.id || '');
    setModelValue(props?.device?.model || '');
    setFleetValue(props?.device?.fleetId || props?.fleetId || '');
    if (props?.device?.vehicleId && props?.device?.vehicleName) {
      const vehicleDevice = {id: props?.device?.vehicleId, name: props?.device?.vehicleName};
      setVehicles([vehicleDevice]);
      setVehicleValue(vehicleDevice);
    } else if (props?.fleetId && props?.vehicleId) {
      fetchVehicle(props?.fleetId, props?.vehicleId);      
    } else {
      setVehicleValue(null);
    }
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleIdChange = (value) => {
    setIdError(null);
    setIdValue(value);
  }

  const _handleModelChange = (value) => {
    setModelError(null);
    setModelValue(value);
  }

  const _handleFleetChange = (value) => {
    setFleetError(null);
    setFleetValue(value);
    setVehicles([]);
    setVehicleInputText('');
    _handleVehicleChange('')
  }

  const _handleVehicleChange = (value) => {
    setVehicleError(null);
    setVehicleValue(value);
  }

  const _handleVehicleInputTextChange = (value) => {
    setVehicleInputText(value);
  }

  const fetchVehicle = async (fleetId, vehicleId) => {
    try {
      setIsFetchingVehicles(true);
      const response = await getVehicles(null, vehicleId, fleetId);
      setVehicles(response.vehicles);
      if (response.vehicles.length > 0) {
        setVehicleValue(response.vehicles[0]);
      }
    } catch (err) {
      appContext.setErrorMessage(String(err));
    } finally {
      setIsFetchingVehicles(false);
    }
  }

  const fetchVehicles = useMemo(
    () =>
      debounce(async (query, callback) => {
        if (!query?.value) {
          return;
        }

        let id = null;
        let name = null;

        if (uuidValidate(query.value)) {
          id = query.value;
        } else {
          name = query.value;
        }

        try {
          setIsFetchingVehicles(true);
          const response = await getVehicles(name, id, query?.fleetId || null);
          callback(response.vehicles);
        } catch (err) {
          appContext.setErrorMessage(String(err));
        } finally {
          setIsFetchingVehicles(false);
        }

      }, 1000),
    [appContext],
  );

  useEffect(() => {
    if (vehicleInputText === vehicleValue?.name) {
      return;
    }

    fetchVehicles({value: vehicleInputText, fleetId: fleetValue}, (response) => setVehicles(response))
  }, [vehicleInputText, fetchVehicles, fleetValue, vehicleValue]);

  const _save = async () => {
    if (fleetValue && props?.device?.fleetId && props?.device?.fleetId !== fleetValue) {
      setIsConfirming('fleet');
    } else if (vehicleValue?.id && props?.device?.vehicleId && props?.device?.vehicleId !== vehicleValue?.id) {
      setIsConfirming('vehicle');
    } else {
      _doSave();
    }
  }

  const _doSave = async () => {
    let valid = true;
    if (!idValue) {
      setIdError('Id is required');
      valid = false;
    }
    if (!modelValue) {
      setModelError('Model is required');
      valid = false;
    }
    if (!fleetValue) {
      setFleetError('Fleet is required');
      valid = false;
    }
    if (!vehicleValue) {
      setVehicleError('Vehicle is required');
      valid = false;
    }
    if (!valid) {
      return;
    }

    const data = {id: props?.device?.id || idValue, model: modelValue, fleetId: fleetValue, vehicleId: vehicleValue.id};

    try {
      setIsSaving(true);
      await saveDevice(data);
      props.changedData({vehicleName: vehicleValue.name,  ...data});
      appContext.setSuccessMessage('Device saved successfully');
    } catch (err) {
      appContext.setErrorMessage(String(err));
    } finally {
      setIsSaving(false);
    }
  }

  return <>
    <Grid container direction="column" alignItems="center" >
      <div style={{ width: '100%', paddingTop: 5 }} >
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2} style={{minWidth: 300}}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                style={{ width: '100%' }}
                onChange={(e) => _handleIdChange(e.target.value)}
                disabled={Boolean(isSaving || props?.device?.id)}
                error={Boolean(idError)}
                id="device-id"
                label="ID"
                value={idValue}
                helperText={idError}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="model-label" htmlFor="model-select">Model</InputLabel>
                <Select id="model-select"
                  aria-describedby="model-helper-text"
                  labelId="model-label"
                  value={modelValue}
                  error={Boolean(modelError)}
                  label="Model"
                  onChange={(e) => _handleModelChange(e.target.value)}
                  disabled={isSaving}
                >
                  <MenuItem key="model-menu-item-all" value="" sx={{height: 36}}> </MenuItem>
                  {DEVICE_MODELS.map((model) => <MenuItem key={`model-menu-item-${model.id}`} value={model.id}>{model.name}</MenuItem>)}
                </Select>
                <FormHelperText error={Boolean(modelError)} id="model-helper-text">{modelError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="fleet-label" htmlFor="fleet-select">Fleet</InputLabel>
                <Select id="fleet-select"
                  aria-describedby="fleet-helper-text"
                  labelId="fleet-label"
                  value={fleetValue}
                  error={Boolean(fleetError)}
                  label="Fleet"
                  onChange={(e) => _handleFleetChange(e.target.value)}
                  disabled={isSaving}
                >
                  <MenuItem key="fleet-menu-item" value="" sx={{height: 36}}></MenuItem>
                  {appContext.allFleets.map((fleet) => <MenuItem key={`fleet-menu-item-${fleet.id}`} value={fleet.id}>{fleet.name}</MenuItem>)}
                </Select>
                <FormHelperText error={Boolean(fleetError)} id="fleet-helper-text">{fleetError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
              <FormControl fullWidth>
                <Autocomplete
                  id="vehicle-select"
                  style={{width: '100%'}}
                  getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.name
                  }
                  filterOptions={(x) => x}
                  options={vehicles}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  value={vehicleValue}
                  noOptionsText="Search by ID or initial name"
                  onChange={(event, newValue) => {
                    _handleVehicleChange(newValue)
                  }}
                  onInputChange={(event, newInputValue) => {
                    _handleVehicleInputTextChange(newInputValue)
                    setVehicleInputText(newInputValue);
                  }}
                  inputValue={vehicleInputText}
                  renderInput={(params) => (
                    <TextField {...params} label="Vehicle" fullWidth />
                  )}
                  disabled={isSaving || !fleetValue}
                />
                {isFetchingVehicles && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
                <FormHelperText error={Boolean(vehicleError)} id="vehicle-helper-text">{vehicleError}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Grid item container direction="row" justifyContent="space-evenly" position="relative" marginTop={4}>
          <Button variant="outlined" disabled={isSaving} onClick={() => props.changedData(null)}>Back</Button>
          <Button variant="contained" disabled={isSaving} onClick={_save}>Save</Button>
            {isSaving && (
            <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
        </Grid>
      </div>
    </Grid>
    <Dialog
      open={Boolean(isConfirming)}
      onClose={() => setIsConfirming('')}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirm?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to move this device to another {isConfirming}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsConfirming('')}>No</Button>
        <Button onClick={_doSave}>Yes</Button>
      </DialogActions>
    </Dialog>
  </>;
}

import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, MenuItem, Select, TextField, FormControl, InputLabel, FormHelperText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import AppContext from '../components/AppContext';
import { saveVehicle } from '../apis/vehicle_api';

export default function Vehicle(props) {
  const [isConfirming, setIsConfirming] = React.useState(false);
  const [isNew, setIsNew] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [nameValue, setNameValue] = useState('');
  const [nameError, setNameError] = useState(null);
  const [fleetValue, setFleetValue] = useState('');
  const [fleetError, setFleetError] = useState(null);
  
  const appContext = useContext(AppContext);
  
  useEffect(() => {
    setIsNew(!Boolean(props?.vehicle));
    setNameValue(props?.vehicle?.name || '');
    setFleetValue(props?.vehicle?.fleetId || '');
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleNameChange = (value) => {
    setNameError(null);
    setNameValue(value);
  }

  const _handleFleetChange = (value) => {
    setFleetError(null);
    setFleetValue(value);
  }

  const _save = async () => {
    if (fleetValue && !isNew && props?.vehicle?.fleetId !== fleetValue) {
      setIsConfirming(true);
    } else {
      _doSave();
    }
  }

  const _doSave = async () => {
      let valid = true;
    if (!nameValue) {
      setNameError('Name is required');
      valid = false;
    }
    if (!fleetValue) {
      setFleetError('Fleet is required');
      valid = false;
    }
    if (!valid) {
      return;
    }

    const data = {id: props?.vehicle?.id, name: nameValue};
    if (isNew) {
      data.fleetId = fleetValue;
    } else {
      data.fleetId = props?.vehicle?.fleetId;
      
      if (props?.vehicle?.fleetId !== fleetValue) {
        data.changedFleetId = fleetValue;
      }
    }

    try {
      setIsSaving(true);
      const response = await saveVehicle(data);
      props.changedData(response);
      appContext.setSuccessMessage('Vehicle saved successfully');
    } catch (err) {
      appContext.setErrorMessage(String(err));
    } finally {
      setIsSaving(false);
    }
  }

  return <>
    <Grid container direction="column" alignItems="center" >
      <div style={{ width: '100%', paddingTop: 5 }} >
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2} style={{minWidth: 257}}>
            <Grid item xs={12} >
              <FormControl fullWidth>
                <InputLabel id="fleet-label" htmlFor="fleet-select">Fleet</InputLabel>
                <Select id="fleet-select"
                  aria-describedby="fleetr-helper-text"
                  labelId="fleet-label"
                  value={fleetValue}
                  error={Boolean(fleetError)}
                  label="Fleet"
                  onChange={(e) => _handleFleetChange(e.target.value)}
                  disabled={isSaving}
                >
                  <MenuItem key="fleet-menu-item" value="" sx={{height: 36}}></MenuItem>
                  {appContext.allFleets.map((fleet) => <MenuItem key={`fleet-menu-item-${fleet.id}`} value={fleet.id}>{fleet.name}</MenuItem>)}
                </Select>
                <FormHelperText error={Boolean(fleetError)} id="fleetr-helper-text">{fleetError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} >
              <TextField
                style={{ width: '100%' }}
                onChange={(e) => _handleNameChange(e.target.value)}
                disabled={isSaving}
                error={Boolean(nameError)}
                id="user-name"
                label="Name"
                value={nameValue}
                helperText={nameError}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid item container direction="row" justifyContent="space-evenly" position="relative" marginTop={4}>
          <Button variant="outlined" disabled={isSaving} onClick={() => props.changedData(null)}>Back</Button>
          <Button variant="contained" disabled={isSaving} onClick={_save}>Save</Button>
            {isSaving && (
            <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
        </Grid>
      </div>
    </Grid>
    <Dialog
      open={Boolean(isConfirming)}
      onClose={() => setIsConfirming(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirm?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to move this vehicle to fleet {fleetValue}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsConfirming(false)}>No</Button>
        <Button onClick={_doSave}>Yes</Button>
      </DialogActions>
    </Dialog>
  </>;
}

import axios from 'axios';
import head from 'lodash.head';
import { buildOptions } from './base_api';

const deleteFleet = async (id) => {
  try {
    await axios.delete('/v1/fleets', await buildOptions({id}));
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

const getFleets = async () => {
  try {
    const response = await axios.get('/v1/fleets', await buildOptions());
    return response.data.data;
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

const getFleet = async (fleetId) => {
  try {
    const response = await axios.get('/v1/fleets', await buildOptions({id: fleetId}));
    return head(response.data.data);
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

const saveFleet = async (fleet) => {
  try {
    await axios.post('/v1/fleets', fleet, await buildOptions());
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

export { deleteFleet, getFleet, getFleets, saveFleet };

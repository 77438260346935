import axios from 'axios';
import head from 'lodash.head';
import { buildOptions } from './base_api';

const DEVICE_MODELS = [
  {id: 'owlcam', name: 'Owlcam'}
];

const deleteDevice = async (id, fleetId, vehicleId) => {
  try {
    await axios.delete('/v1/devices', await buildOptions({id, fleetId, vehicleId}));
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

const getDevice = async (id, fleetId) => {
  try {
    const response = await axios.get('/v1/devices', await buildOptions({id, fleetId}));
    return head(response.data.data);
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

const getDevices = async (id, model, fleetId, vehicleId, lastId, lastFleetId, lastVehicleId) => {
  try {
    const response = await axios.get('/v1/devices', await buildOptions({id, model, fleetId, vehicleId, lastId, lastFleetId, lastVehicleId}));
    return {devices: response.data.data, pageSize: response.data.pageSize};
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

const saveDevice = async (device) => {
  try {
    const response = await axios.post('/v1/devices', device, await buildOptions());
    return response.data.data;
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

export { deleteDevice, getDevice, getDevices, saveDevice, DEVICE_MODELS };

import { initializeApp } from 'firebase/app';
import { browserSessionPersistence, getAuth, getRedirectResult, GoogleAuthProvider, setPersistence, signInWithRedirect } from "firebase/auth";
import Environment from './environment';

const config = Environment.firebaseConfig;
initializeApp(config);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: 'select_account'
});

const auth = getAuth();

const signInWithGoogle = () => {
  signInWithRedirect(auth, provider);
};

const handleSignInRedirect = async () => {
  return setPersistence(auth, browserSessionPersistence).then(() => getRedirectResult(auth));
};

export { auth, handleSignInRedirect, signInWithGoogle };

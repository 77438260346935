import Environment from './environment';
import { auth } from './firebase';

const buildOptions = async (query) => {
  const token = await auth.currentUser.getIdToken();
  let options = {
    baseURL: Environment.apiUrl,
    headers: {
      authorization: `Bearer ${token}`,
      'x-api-key': Environment.apiKey
    }
  };
  if (query) {
    options.params = query;
  }
  return options;
}

export {buildOptions};
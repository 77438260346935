import axios from 'axios';
import head from 'lodash.head';
import { buildOptions } from './base_api';

const deleteVehicle = async (id, fleetId) => {
  try {
    await axios.delete('/v1/vehicles', await buildOptions({id, fleetId}));
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

const getVehicle = async (id, fleetId) => {
  try {
    const response = await axios.get('/v1/vehicles', await buildOptions({id, fleetId}));
    return head(response.data.data);
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

const getVehicles = async (name, id, fleetId, lastId, lastFleetId) => {
  try {
    const response = await axios.get('/v1/vehicles', await buildOptions({name, id, fleetId, lastId, lastFleetId}));
    return {vehicles: response.data.data, pageSize: response.data.pageSize};
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

const saveVehicle = async (vehicle) => {
  try {
    const response = await axios.post('/v1/vehicles', vehicle, await buildOptions());
    return response.data.data;
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

export { deleteVehicle, getVehicle, getVehicles, saveVehicle };

import { findKey } from 'lodash';

const firebaseLocalConfig = {
  apiKey: "AIzaSyA3yH5zBb7zSpNcbhuUCN28cffqWfnLnyg",
  authDomain: "dev-sentinel-app.firebaseapp.com",
  databaseURL: "https://dev-sentinel-app-default-rtdb.firebaseio.com",
  projectId: "dev-sentinel-app",
  storageBucket: "dev-sentinel-app.appspot.com",
  messagingSenderId: "657444577973",
  appId: "1:657444577973:web:ce7ab8b1e78df81b1714fc",
  measurementId: "G-YQEZSFRFKB"
};

const firebaseDevConfig = {
  apiKey: "AIzaSyA3yH5zBb7zSpNcbhuUCN28cffqWfnLnyg",
  authDomain: "admin.dev.sentinel.video",
  databaseURL: "https://dev-sentinel-app-default-rtdb.firebaseio.com",
  projectId: "dev-sentinel-app",
  storageBucket: "dev-sentinel-app.appspot.com",
  messagingSenderId: "657444577973",
  appId: "1:657444577973:web:ce7ab8b1e78df81b1714fc",
  measurementId: "G-YQEZSFRFKB"
};

const firebaseProdConfig = {
};

class Environment {
  static get envs() {
    return {
      local: /localhost:3000/,
      development: /admin\.dev\.sentinel\./,
      production: /admin\.sentinel\./
    };
  }

  static get env() {
    const url = window.location.href;
    return findKey(this.envs, env => env.test(url)) || 'local';
  }

  static get firebaseConfig() {
    const configs = {
      local: firebaseLocalConfig,
      development: firebaseDevConfig,
      production: firebaseProdConfig
    };
    return configs[this.env];
  }

  static get apiKey() {
    const keys = {
      local: 'teEJoK9Kya25c8N3CgsH0ayMU8hrgIR08IR2tMJR',
      development: 'teEJoK9Kya25c8N3CgsH0ayMU8hrgIR08IR2tMJR',
      production: ''
    };
    return keys[this.env];
  }

  static get apiUrl() {
    const urls = {
      local: 'https://api.dev.sentinel.video',
      development: 'https://api.dev.sentinel.video',
      production: 'https://api.sentinel.video'
    };
    return urls[this.env];
  }
}

export default Environment;

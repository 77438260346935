import React from 'react';

const AppContext = React.createContext({
  setErrorMessage: () => {},
  setSuccessMessage: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  isAdmin: false,
  setIsAdmin: () => {},
  role: undefined,
  setRole: () => {},
  allFleets: [],
  setAllFleets: () => {},
});

export default AppContext;

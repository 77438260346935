import React, { useContext, useEffect } from 'react';
import AppContext from '../components/AppContext';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { deleteFleet, getFleets } from '../apis/fleet_api';
import Fleet from './Fleet';

export default function Fleets() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletingId, setDeletingId] = React.useState(null);
  const [editingId, setEditingId] = React.useState(null);
  const appContext = useContext(AppContext);

  const _handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const _handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const _loadFleets = async () => {
    try {
      setIsLoading(true);
      const resp = await getFleets();
      appContext.setAllFleets(resp);
    } catch (err) {
      console.error(err);
      appContext.setErrorMessage(String(err));
    } finally {
      setIsLoading(false);
    }
  }

  const _changedData = (data) => {
    if (data) {
      if (isAdding) {
        appContext.allFleets.push(data);
        appContext.setAllFleets(appContext.allFleets);  

      } else {
        const index = appContext.allFleets.findIndex((i) => i.id === editingId);
        if (index > -1) {
          appContext.allFleets[index] = { ...appContext.allFleets[index], ...data }
          appContext.setAllFleets(appContext.allFleets);    
        }
      }
    }

    setEditingId(null);
    setIsAdding(false);
  }

  const _delete = async () => {
    try {
      setIsDeleting(true);
      await deleteFleet(deletingId);
      const index = appContext.allFleets.findIndex((i) => i.id === deletingId);
      if (index > -1) {
        appContext.allFleets.splice(index, 1);
      }
      appContext.setSuccessMessage('Fleet removed successfully');
    } catch (err) {
      console.error(err);
      appContext.setErrorMessage(String(err));
    } finally {
      setDeletingId(null)
      setIsDeleting(false);
    }
  }

  useEffect(() => {
    const init = () => {
      if (appContext.allFleets.length === 0) {
        _loadFleets()
      }
    };
  
    init();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <>

    <Grid container justifyContent="space-between" alignItems="left" flexDirection={{ xs: 'column', sm: 'row' }} spacing={2} marginBottom={2}>
      <Grid>
        <Typography variant="h4" gutterBottom>Fleets</Typography>
      </Grid>
      <Grid container columnSpacing={1} flexDirection={{ xs: 'column', sm: 'row' }} >
        <Grid paddingTop={{xs: 0, sm: 2}}>
          <Button onClick={_loadFleets} disabled={isDeleting}>Reload fleets</Button>
        </Grid>
        <Grid paddingTop={{xs: 0, sm: 2}} paddingLeft={0}>
          <Button onClick={() => setIsAdding(true)} style={{paddingLeft: 2}} disabled={isDeleting}>New</Button>
        </Grid>
      </Grid>
    </Grid>

    {isLoading && (<div style={{textAlign: 'center', marginTop: 100}}> <CircularProgress /></div>)}

    {!isLoading && Boolean(appContext.allFleets.length) && (
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  key={'header-id'}
                  style={{ fontSize: 'large', minWidth: 70 }}
                >
                  ID
                </TableCell>
                <TableCell
                  key={'header-name'}
                  style={{ fontSize: 'large' }}
                >
                  Name
                </TableCell>
                <TableCell key='header-edit' align='right' width={100}>
                  
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appContext.allFleets
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((fleet, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={`fleet-${fleet.id}`}>
                      <TableCell key={`fleet-${fleet.id}-column-id`}>
                        {fleet.id}
                      </TableCell>
                      <TableCell key={`fleet-${fleet.id}-column-name`}>
                        {fleet.name}
                      </TableCell>
                      <TableCell key={`fleet-edit-${fleet.id}`} align='right' width={100}>
                        <div style={{display: 'flex'}}>
                          <IconButton onClick={() => setEditingId(fleet.id)} disabled={deletingId || editingId}><EditIcon /></IconButton>
                          {!(isDeleting &&  deletingId === fleet.id) && (
                            <IconButton onClick={() => setDeletingId(fleet.id)} disabled={deletingId || editingId}><DeleteIcon /></IconButton>
                          )}
                          {isDeleting && deletingId === fleet.id && (
                            <div style={{paddingLeft: 8, paddingTop: 10}}>
                              <CircularProgress
                                size={24}
                              />
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={appContext.allFleets.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={_handleChangePage}
          onRowsPerPageChange={_handleChangeRowsPerPage}
        />
      </Paper>
    )}
    </>
    <Dialog open={isAdding} >
      <DialogTitle>New fleet</DialogTitle>
      <DialogContent>
        <Fleet changedData={_changedData} />
      </DialogContent>
    </Dialog>
    <Dialog open={Boolean(editingId)} >
      <DialogTitle>Edit fleet</DialogTitle>
      <DialogContent>
        <Fleet fleet={appContext.allFleets.find((i) => i.id === editingId)} changedData={_changedData} />
      </DialogContent>
    </Dialog>
    <Dialog
      open={Boolean(deletingId && !isDeleting)}
      onClose={() => setDeletingId(null)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirm?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove this fleet?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeletingId(null)}>No</Button>
        <Button onClick={_delete}>Yes</Button>
      </DialogActions>
    </Dialog>
  </>;
}

import axios from 'axios';
import head from 'lodash.head';
import { buildOptions } from './base_api';

const saveUser = async (user) => {
  try {
    await axios.post('/v1/users', user, await buildOptions());
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

const getUser = async (userId) => {
  try {
    const response = await axios.get('/v1/users', await buildOptions({id: userId}));
    return head(response.data.data);
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};
const getUsers = async (name, email, fleetId, lastId) => {
  try {
    const response = await axios.get('/v1/users', await buildOptions({name, email, fleetId, lastId}));
    return {users: response.data.data, pageSize: response.data.pageSize} ;
  } catch (err) {
    throw(Error(err?.response?.data?.errorMessage || String(err)));
  }
};

export { getUser, getUsers, saveUser };
